import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const WOLTADVISOR_LOCALES = {
	WOLTAIR_CZ: 'kalkulacka',
	WOLTAIR_PL: 'kalkulator',
	WOLTAIR_DE: 'rechner',
	WOLTAIR_IT: 'calcolatrice'
}

export const WOLTADVISOR = WOLTADVISOR_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return WOLTADVISOR === param
}) satisfies ParamMatcher
